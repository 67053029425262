<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + karaoke.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + karaoke.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link :to="{name: 'AdminKaraokeEdit', params: {id: karaoke.id}}">
        {{ karaoke.title }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link :to="{name: 'UserDetail', params: {id: karaoke.uploader.id}}" v-if="karaoke.uploader">
        {{ karaoke.uploader.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(karaoke.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link class="mr-3" :to="{name: 'SongDetail', params: {id: karaoke.id, slug: karaoke.slug, prefix: 'dan-ca'}}">
        Xem
      </router-link>
      <router-link :to="{name: 'AdminKaraokeEdit', params: {id: karaoke.id}}" class="mr-3">
        Sửa
      </router-link>
      <a href="javascript:void(0)" v-on:click="deleteKaraoke(karaoke)">Xóa</a>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminKaraokeItem",
  props: {
    karaoke: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    deleteKaraoke(karaoke) {
      this.$emit("deleteKaraoke", karaoke);
    }
  },
  created() {
    this.isChecked = this.karaoke.is_checked;
  },
  watch: {
    "karaoke.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedKaraoke", this.karaoke, newVal);
      }
    },
  }
}
</script>
